import React, { useState, useEffect } from "react";
import "./ProductDetail.css";
import { useStateValue } from "./StateProvider";
import Returns from "./Returns";
import Home from "./Home";
import Shipping from "./Shipping";
import Pricing from "./Pricing";
import { useParams, useNavigate } from "react-router-dom";
import productsdb from "./productsdb";
//
function ProductDetail({ id, title, image, price, rating }) {
  const navigate = useNavigate();
  // to pull data from datalayer
  const [{ basket }, dispatch] = useStateValue(); //  <== if we distructure the state to get the basket
  const [productDetails, setProductDetails] = useState(productsdb);
  const myId = useParams();
  let productId = myId;
  //const [state , dispatch ] = useStateValue();        // state is the state of the global store
  const productDetail = productDetails.find(
    (prd) => prd.id === productId.productId
  );
  // lets dispatch the items selected
  const addToBasket = (event) => {
    event.preventDefault();
    productId = "";
    // dispatching the item into the data layer
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        // id: id,
        id: basket?.length + 1,
        title: productDetail.title,
        image: productDetail.image,
        price: productDetail.price,
        rating: productDetail.rating,
      },
    });
    navigate("/Home");
  };
  return (
    <>
      <div className="product_detail">
        <img src={productDetail.image} alt="prd" />

        <div className="product_detail_info" key={id}>
          <h6>{productDetail.title}</h6>
          <div className="product_detail_price">
            <strong>$</strong>
            <strong>{productDetail.price}</strong>
          </div>
          <div className="product_rating">
            {Array(productDetail.rating)
              .fill()
              .map((_, index) => {
                return (
                  <p key={index} className="star_color">
                    &#9734;
                  </p>
                );
              })}
          </div>
        </div>
        <div className="product_detail_info">
          {" "}
          <h6>{productDetail.description}</h6>
        </div>
        <div className="product_detail">
          <button onClick={addToBasket}>Add to Basket</button>
          <div
            style={{
              display: "flex",
              flexWrap: true,
              gap: 2,
              padding: 10,
              marginTop: 5,
              marginBottom: 5,
              flexDirection: "row",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {<Shipping />}
            {`  |  `}
            {<Pricing />}
            {`  |  `}
            {<Returns />}

            <span
              style={{
                color: "#111",
                background: "#fffffb",
                border: " 0.5px solid",
                borderColor: "#efb82c #efb82c #efb82c",
                marginLeft: "70px",
                marginTop: "auto",
                cursor: "pointer",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "50px",
              }}
              onClick={() => navigate("/Home")}
            >
              Close
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
