import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Checkout from "./Checkout";
import Product from "./Product";
import ProductDetail from "./ProductDetail";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LogIn from "./LogIn";
import { useEffect } from "react";
import { auth } from "./firebase";
import { useStateValue } from "./StateProvider";
import Payment from "./Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Orders from "./Orders";
import PolicyNotice from "./PolicyNotice";
import ConditionsOfUse from "./ConditionsOfUse";
import AdsPrivacyChoices from "./AdsPrivacyChoices";
import EmailForm from "./EmailForm";
import * as React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

//const stripeApiKey='pk_test_51L09miAhbJupDqIgKB1UEJLJ1Zoan23ReiUuJLAE9QuMgzTiwEVq0fDEUpad0LNOpFWzCmUsNyVa5nbQ549fZrBv00rGuUORL3';
const promise = loadStripe(
  // no need to hide publishable key :
  "pk_test_51L09miAhbJupDqIgKB1UEJLJ1Zoan23ReiUuJLAE9QuMgzTiwEVq0fDEUpad0LNOpFWzCmUsNyVa5nbQ549fZrBv00rGuUORL3"
);

const theme = createTheme({
  typography: {
    h1: { fontSize: "3rem" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
      "3xl": 1920,
      "4xl": 2560,
      "5xl": 3200,
    },
  },
});

function App() {
  const [{}, dispatch] = useStateValue(); // useStateValue is from StateProvider.js
  //.. listener  thst Triggers whenever user logs in or logs out

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      // whenever a page is refreshed :
      console.log("\n\n App.js:::The current user is ", authUser + " \n\n");

      if (authUser) {
        // if user is looged in, dispatch a user obj to global storage
        dispatch({ type: "SET_USER", user: authUser });
      } else {
        //if user is logged out dispatch that user's obj to global storage
        dispatch({ type: "SET_USER", user: null });
      }
    });
  }, []);
  return (
    <ThemeProvider
      theme={responsiveFontSizes(theme, {
        breakpoints: ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"],
        factor: 3,
      })}
    >
      <>
        <Router>
          <div className="app">
            <Routes>
              <Route path="*" element={<Header />} />
              <Route path="/EmailForm" element={<EmailForm />} />
             
             
             
             
             
             
              {/* remove Header from this <Routes>,
                                                          but show Header for everything outside this <Routes> */}
              <Route path="/LogIn" element={<LogIn />} />
            </Routes>
            {/*  use stripe Element to protect our    "/payment"    */}
            <Elements stripe={promise}>
              <Routes>
                <Route exact path="/payment" element={<Payment />} />
                <Route path="/Orders" element={<Orders />} />
              </Routes>
            </Elements>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Home/:productId" element={<Home />} />
              <Route path="/checkout" element={<Checkout />} />
            </Routes>
          </div>
        </Router>
        <Router>
          <Routes>
          <Route path="/ConditionsOfUse" element={<ConditionsOfUse />} />
 <Route path="/PolicyNotice" element={<PolicyNotice />} />
 <Route
   path="/AdsPrivacyChoices"
   element={<AdsPrivacyChoices />}
 />
            <Route path="*" element={<Footer />} />
          </Routes>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
