import React, { useState, useEffect } from "react";
import "./Home.css";
import Product from "./Product";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import productsdb from "./productsdb";
import ProductDetail from "./ProductDetail";
import { useLocation, useParams, Link } from "react-router-dom";
function Home() {
  const [products, setProducts] = useState(productsdb);

  const { productId } = useParams();
  // when page loads/refreshes
  const { state } = useLocation();
  const slides = [
    {
      id: 1,
      url: "../images/home_slides/sales_blue.jpg",
    },
    {
      id: 2,
      url: "../images/home_slides/sales_purple.jpg",
    },
    {
      id: 3,
      url: "../images/home_slides/sales_pink2.jpg",
    },
    {
      id: 4,
      url: "../images/home_slides/sales_black.jpg",
    },
  ];
  const slideShow = [...slides];
  return (
    <Box className="home" sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} style={{ backgroundColor: "white" }}>
      <hr />
        <Grid item xs={12}>
          {slideShow.map((slide) => {
            let i = 0;
            if (slide.id === i)
              return (
                <Grid
                  item
                  sx={{
                    width: { xs: "90%", md: "90%" },
                    height: "auto",
                    mb: { xs: "-280px", md: "-180px" },
                  }}
                  className="home_slidesShow_image"
                  style={{ display: "flex" }}
                >
                  <img src={slide.url} alt="slides" />
                </Grid>
              );
          })}
          )
        </Grid>
        {state && (
          <Grid item xs={12}>
            <Link
              to={`/Home/${state?.result.id}`}
              style={{ textDecoration: "none" }}
            >
              <Product
                id={state?.result.id}
                title={state?.result.title}
                price={state?.result.price}
                rating={state?.result.rating}
                image={state?.result.image}
              />
            </Link>
          </Grid>
        )}
        {productId && (
          <Grid item xs={12} className="home__row">
            <ProductDetail />
          </Grid>
        )}

        {products.map((item, i) => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={3} className="home__row">
              <Link to={`/Home/${item.id}`} style={{ textDecoration: "none" }}>
                <Product
                  id={item.id}
                  title={item.title}
                  price={item.price}
                  rating={item.rating}
                  image={item.image}
                />
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
export default Home;
//   return (
//     <div className="home">
//       <div className="home_container">
//         <img className="home_image" src="../images/homebackground.jpg" />
//         {/* // src='https://images-eu.ssl-images-amazon.com/images/G/02/digital/video/merch2016/Hero/Covid19/Generic/GWBleedingHero_ENG_COVIDUPDATE__XSite_1500x600_PV_en-GB._CB428684220_,jpg'
//             //             alt='' */}

//         <div className="home__row">
//           <Product
//             id="1"
//             title="Canon - EOS R6 Mirrorless Camera with RF 24-105mm f/4-7.1 IS STM Lens - Black"
//             price={1455.0}
//             rating={5}
//             image={"../images/Canon-EOSR6.jpg"}
//           />
//           <Product
//             id="2"
//             title="Sony -  Camera with RF 24-105mm f/4-7.1 IS STM Lens - Black"
//             price={2255.0}
//             rating={4}
//             image={"../images/Sony-CameraRF.jpg"}
//           />
//         </div>
//         <div className="home__row">
//           <Product
//             id="3"
//             title="Epson V11HA34920 LCD WUXGA EB-PU1007W Laser Projector 7000 Lumens 4K (White)"
//             price={8899}
//             rating={4}
//             image={
//               "../images/Epson V11HA34920LCD WUXGAEB-PU1007WLaser Projector.jpg"
//             }
//           />
//           <Product
//             id="4"
//             title="Epson Pro L1200U WUXGA 3LCD Projector w/ 4K Enhancement & Standard Lens V11H734020"
//             price={5999.0}
//             rating={4}
//             image={"../images/EpsonProL1200U WUXGA3LCD Projector.jpg"}
//             // image ={'https://images-na.ssl-images-amazon.com/images/I/816ctt5WV5L._AC_SX385_.jpg'}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Home;
