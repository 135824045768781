import React from "react";
import "./SearchResultList.css";
import SearchResult from "./SearchResult";
const SearchResultList = ({ results }) => {
  return (
    <div className="result-list">
      <ul>
        {results?.map((result, id) => {
          return (
            <li key={id}>
              <SearchResult result={result} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchResultList;
