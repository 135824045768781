import {createContext , useContext, useReducer} from "react";

//prepares the dataLayer
export const StateContext = createContext();

//wraps our app and provide DataLayer to every of our component
export const StateProvider =({reducer,initialState, children})=>(
    <StateContext.Provider value={useReducer(reducer,initialState)}>
        {children}
    </StateContext.Provider>
);

// Pull information from the data layer
export const useStateValue = ()=>useContext(StateContext);
