import * as React from "react";
import { DialogTitle } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={50} ref={ref} variant="filled" {...props} />;
});
export default function AdsPrivacyChoices2() {
  const [open, setOpen] = React.useState(false);
  const [isYes, setIsYes] = React.useState(false);
  const handleYesClick = () => {
    setIsYes(true);
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsYes(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <div>
        <label
          style={{
            height: 20,
            width: 60,
            justifyItems: "center",
            color: "gray",
            "&:hover": {
              cursor: "pointer",
              color: "white",
            },
          }}
          onClick={handleClickOpen}
        >
          Ads Privacy and Choices
        </label>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>dollarealms.com</DialogTitle>
        <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h3" component="div" gutterBottom>
              <h3>Ads Privacy</h3>
              <h5>{`(Choices you can make)`}</h5>
            </Typography>
            <Typography
              variant="h6"
              fontWeight="700"
              component="div"
              gutterBottom
            >
              August 24, 2023
              <hr />
            </Typography>
            <Typography variant="body2">
              <p>
                To display products, services, and features that might interest
                <br />
                you, we show cross-context behavioral ads and interest-based ads
                <br />
              </p>
              <br />
            </Typography>
            <Typography
              variant="h6"
              fontWeight="700"
              component="div"
              gutterBottom
            >
              <p>Cross-context behavioral ads.</p>
            </Typography>
            <Typography variant="body2">
              It’s your right under some US state laws to opt out of
              <br />
              cross-context behavioral ads. Cross-context behavioral ads use
              <br />
              data from one site or app to advertise to you on a different
              <br />
              company's site or app
              <br />
              <ul>
                <li>Allow cross-context behavioral ads</li>
                <li>Opt out of cross-context behavioral ads</li>
              </ul>
              <br />
              <Button>Save preferences</Button>
              <h5>If you opt out, we won’t:</h5>
              <ul>
                <li>
                  Use information about your use of our store and services to
                  deliver ads to you off of Dollarealms's own properties
                </li>
                <li>
                  Deliver ads to you on Dollarealms that use information
                  provided by the advertiser
                </li>
              </ul>
            </Typography>
            <Typography variant="body2">
              <br />
              We’ll continue to share your data with our co-branded
              subsidiaries. We’ll also continue to show you ads based on your
              activity on Dollarealms’s sites and apps and those of our
              co-branded subsidiaries <br />
              To avoid having to change your settings every time you use
              Dollarealms, sign in. If you’re not signed in, your choices might
              not be recognized when you use a different browser or delete your
              cookies.
              <br />
              <br />
            </Typography>
            <Typography variant="h5" component="div" sx={{ marginTop: "20px" }}>
              Interest-based ads
            </Typography>
            Dollarealms delivers relevant ads to customers on Dollarealms and
            beyond. The information on this page relates specifically to
            cross-context behavioral ads, but you can visit our interest-based
            ads page to learn more
            <br />
          </DialogContentText>
        </DialogContent>
        <hr />
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={isYes}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          We are always here for you
        </Alert>
      </Snackbar>
    </Stack>
  );
}
