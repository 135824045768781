import React from "react";
import "./Footer.css";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import ConditionsOfUseV2 from "./ConditionsOfUseV2";
import PolicyNoticeV2 from "./PolicyNoticeV2";
import AdsPrivacyChoices2 from "./AdsPrivacyChoices2";
import EmailForm2 from "./EmailFormV2";
function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <hr />
      <Grid
        className="footer"
        direction="column"
        container
        lg={12}
        item
        spacing={0}
        bgcolor="black"
      >
        <Grid
          item
          xs={12}
          lg={3}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
          }}
        >
          <Box
            style={{
              textDecoration: "none",
              justifyItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ConditionsOfUseV2 />
          </Box>
          <Box
            style={{
              justifyItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {" "}
            <PolicyNoticeV2 />
          </Box>

          <Box
            style={{
              justifyItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <AdsPrivacyChoices2 />
          </Box>
          <Box
            style={{
              justifyItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              textDecoration: "none",
            }}
          >
            <EmailForm2 />
          </Box>
          <br />
          <Box
            style={{
              justifyItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <span className="policies">
              Copyright &copy; {new Date().getFullYear()}, Dollarealms LLC
            </span>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default Footer;
