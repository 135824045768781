import { Card, CardContent, Grid, Typography, Button } from "@mui/material";

const AdsPrivacyChoices = () => {
  return (
    <Card sx={{ minWidth: 275, marginLeft: "auto", marginRight: "auto" }}>
      <CardContent>
        <Grid>
          <Grid item>
            <Typography variant="h3" component="div" gutterBottom>
              Ads Privacy ( Choices you can make )
            </Typography>
            <Typography
              variant="h6"
              fontWeight="700"
              component="div"
              gutterBottom
            >
              August 24, 2023
              <hr />
            </Typography>
            <Typography variant="body2">
              <p>
                To display products, services, and features that might interest
                <br />
                you, we show cross-context behavioral ads and interest-based ads
                <br />
              </p>
              <br />
            </Typography>
            <Typography
              variant="h6"
              fontWeight="700"
              component="div"
              gutterBottom
            >
              <p>Cross-context behavioral ads.</p>
            </Typography>
            <Typography variant="body2">
              It’s your right under some US state laws to opt out of
              <br />
              cross-context behavioral ads. Cross-context behavioral ads use
              <br />
              data from one site or app to advertise to you on a different
              <br />
              company's site or app
              <br />
              <ul>
                <li>Allow cross-context behavioral ads</li>
                <li>Opt out of cross-context behavioral ads</li>
              </ul>
              <br />
              <Button>Save preferences</Button>
              <h5>If you opt out, we won’t:</h5>
              <ul>
                <li>
                  Use information about your use of our store and services to
                  deliver ads to you off of Dollarealms's own properties
                </li>
                <li>
                  Deliver ads to you on Dollarealms that use information
                  provided by the advertiser
                </li>
              </ul>
            </Typography>
            <Typography variant="body2">
              <br />
              We’ll continue to share your data with our co-branded
              subsidiaries. We’ll also continue to show you ads based on your
              activity on Dollarealms’s sites and apps and those of our
              co-branded subsidiaries <br />
              To avoid having to change your settings every time you use
              Dollarealms, sign in. If you’re not signed in, your choices might
              not be recognized when you use a different browser or delete your
              cookies.
              <br />
              <br />
            </Typography>
            <Typography variant="h5" component="div" sx={{ marginTop: "20px" }}>
              Interest-based ads
            </Typography>
            Dollarealms delivers relevant ads to customers on Dollarealms and
            beyond. The information on this page relates specifically to
            cross-context behavioral ads, but you can visit our interest-based
            ads page to learn more
            <br />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default AdsPrivacyChoices;
