import React, { useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "./firebase";
 import "./LogIn.css";
import Home from "./Home";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("..");
  const [logedInUser, setLogedInUser] = useState("Gest");

  const navigate = useNavigate();
  const onLogin = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in

        const user = userCredential.user;
        alert("Log in successful " + " \n\n Click Ok to continue ....");
        navigate("/Home");
        // ...
      })
      .catch((error) => {
        alert(
          error.message +
            "\n\n Invalid Login !!!! ....\n\nEnter the correct username and password"
        );
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }; // End of Sign in

  const Register = (e) => {
    e.preventDefault();
    //const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        alert(
          user.email +
            " was created successfully \n\nClick OK or Close to continue"
        );
        navigate("/payment");
        console.log(userCredential);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("User not created due to \n\n" + error.message);
        // ..
      });
  }; // End of Register

  return (
    <div className="login">
      <Link to="/">
        {" "}
        {/* clicking the company logo navigates user to homepage */}
        <img
          className="login_logo"
          //
          src="../images/Home_logo_.jpg"
        />
      </Link>
      <div className="login_container">
        <h1> Sign in</h1>
        <form>
          <h5>E-mail</h5>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />{" "}
          {/* input.text =>>> input.value */}
          <h5>Password</h5>
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <h5 className="login_needHelp">Need help?</h5>
          <Link to="/">
            <h5 className="login_forgotPassword">Forgot your password?</h5>{" "}
          </Link>
          <Link to="/">
            {" "}
            <h5 className="login_otherIssues">
              Other issues with Sign-In
            </h5>{" "}
          </Link>
          <button
            className="login_signInButton"
            type="submit"
            onClick={(event) => onLogin(event)}
          >
            Sign In
          </button>
          <p>
            By continuing, you agree to Dollarealms Conditions of Use and
            Privacy Notice.
          </p>
          <button className="login_registerButton" onClick={Register}>
            Create your purchase account
          </button>
        </form>
      </div>{" "}
      {/* login signIncontainer__title*/}
    </div>
  );
};

export default SignIn;
