import React, { useState } from "react";
import "./SearchResult.css";
import Product from "./Product";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
const SearchResult = ({ result }) => {
  //
  const navigate = useNavigate();
  const toSearchDetails = (e) => {
    // take the result to Home for display
e.preventDefault()
    navigate("/Home", {
      state: { result },
    });
    //once user selects, refresh to clear suggested search results
    window.location.reload(true);
  };
  return (
    <div>
      <div className="search-result" onClick={toSearchDetails}>
        {result.title}
      </div>
    </div>
  );
};
export default SearchResult;
