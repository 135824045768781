import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import productsdb from "./productsdb";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { auth } from "./firebase";
// import {useLocalStorage} from "./useLocalStorage"
import SearchIcon from "@mui/icons-material/Search";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SearchResults from "./SearchResults";
import SearchResultList from "./SearchResultList";
const theme = createTheme({
  typography: {
    h1: { fontSize: "5rem" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
      "3xl": 1920,
      "4xl": 2560,
      "5xl": 3200,
    },
  },
});

function Header() {
  const [input, setInput] = useState("");
  const [results, setResults] = useState([]);
  let entry = "";
  // 1. pull the basket obj from state storage so we can count basket items
  // 2. pull the user obj from state storage so we can display the LogIn status at the page h
  //
  const [{ basket, user }, dispatch] = useStateValue(); // getting ready to count items in ba
  // disructured state to acquire basket
  const navigate = useNavigate();

  React.useEffect(() => {
    //we must immediately convert the JSON data  to a JavaScript data object:
    const data = JSON.parse(localStorage.getItem("dls-basket"));
    if (data) {
      // console.log("LocalStorage dls-Basket: ", data);
      dispatch({
        type: "REFRESH_BASKET",
        item: data,
      });
      // console.log("Shopping basket from storage: ", basket);
    }
  }, []);
  //
  React.useEffect(() => {
    if (basket.length >= 1) {
      localStorage.setItem("dls-basket", JSON.stringify([...basket]));
    } else {
      // when user deletes the one and only item in the basket
      // so no need to have anything in localstorage basket
      localStorage.clear();
    }
  }, [basket]);
  //
  const fetchData = (value) => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((json) => {
        entry = value;
        // if (resultIsClicked) {
        // entry = "";
        // }
        // console.log(json);
        // const results = json.filter((user) => {
        const results = productsdb.filter((item) => {
          return (
            entry &&
            item &&
            item.title &&
            item.title.toLowerCase().includes(entry.toLowerCase())
          );
        });
        setResults(results);
      })
      .catch((er) => console.log(er.message));
  };
  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
    // alert(
    // "Available products are already displayed on this page\n Thanks for shopping with us !"
    // );
  };
  const handleAuthentication = (e) => {
    // e.preventDefault();
    if (user) {
      // if exists
      auth.signOut();
      navigate("/Home");
    }
  };
  return (
    <Grid
      item
      container
      style={{
        height: "160px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#045",
        position: "-webkit-sticky",
        position: "sticky",
        top: 0,
        zIndex: "100",
      }}
    >
      <ThemeProvider
        theme={responsiveFontSizes(theme, {
          breakpoints: [
            "xs",
            "sm",
            "md",
            "lg",
            "xl",
            "2xl",
            "3xl",
            "4xl",
            "5xl",
          ],
          factor: 5,
        })}
      >
        <Grid item direction="row" container lg={12} spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box style={{ display: "flex" }}>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Link to="/">
                  <img
                    className="header_logo"
                    src="../images/logo.jpg"
                    alt=""
                  />
                </Link>
              </Box>
              <span className="header_search">
                <input
                  value={input}
                  onChange={(e) => handleChange(e.target.value)}
                  className="header_searchInput"
                  placeholder="search dollarealms.com..."
                  type="text"
                />
                <span className="header_searchIcon" oClick={null}>
                  <SearchIcon
                    style={{
                      color: "rgb(0, 0, 0,0.4)",
                      height: "45px",
                      width: "45px",
                      position: "relative",
                    }}
                  />
                </span>
              </span>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box className="header_nav">
              <Link to={!user && "/Login"} style={{ textDecoration: "none" }}>
                <div className="header_option" onClick={handleAuthentication}>
                  <span className="header_optionLineOne">
                    Hello {!user ? "Guest" : user.email}{" "}
                  </span>
                  <span className="header_optionLineTwo">
                    {user ? "Sing Out" : "Sign In"}
                  </span>
                </div>
              </Link>
              <Link to="/orders" style={{ textDecoration: "none" }}>
                <div className="header_option">
                  <span className="header_optionLineOne">Returns</span>
                  <span className="header_optionLineTwo">& Orders</span>
                </div>
              </Link>
              <div className="header_option">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "18px",
                  }}
                  to="/Home"
                >
                  Shop
                </Link>
              </div>
              <Link to="Checkout" style={{ textDecoration: "none" }}>
                <div className="header_option">
                  <span className="header_optionLineTwo header_basketCount">
                    {basket?.length}
                  </span>
                  <span>
                    <ShoppingCartOutlinedIcon sx={{ margin: 0, padding: 0 }} />
                  </span>
                  {/* // the auth user is not null display Sign Out  : */}
                  {/*  ?. optional chaining is to skip any errors, eg due to null value     */}
                </div>
              </Link>
            </Box>
          </Grid>
          <span
            style={{
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <SearchResults setResults={setResults} />
            <SearchResultList results={results} />
          </span>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}

export default Header;

//
// function Header() {
// 1. pull the basket obj from state storage so we can count basket items
// 2. pull the user obj from state storage so we can display the LogIn status at the page header

// const [{ basket, user }, dispatch] = useStateValue(); // getting ready to count items in basket.
// disructured state to acquire basket
//
// const navigate = useNavigate();
// useEffect(() => {
//   if (basket.length === 0) navigate("/Home");
// }, [basket]);
//
// const handleAuthentication = (e) => {
// e.preventDefault();
// if (user) {
//  if exists
// auth.signOut();
// navigate("/Home");
// }
// };
// return (
// <ThemeProvider
// theme={responsiveFontSizes(theme, {
// breakpoints: ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"],
// factor: 5,
// })}
// >
{
  /* <Grid */
}
// item
// className="home"
// direction="row"
// container
// lg={12}
// spacing={2}
// bgcolor="black"
// >
{
  /* <Grid item xs={12} lg={6}> */
}
{
  /* <Box style={{ display: "flex" }}> */
}
{
  /* <Box sx={{ display: { xs: "none", sm: "block" } }}> */
}
{
  /* <Link to="/"> */
}
{
  /* <img className="header_logo" src="../images/logo.jpg" alt="" /> */
}
{
  /* </Link> */
}
{
  /* </Box> */
}
{
  /* <span className="header_search"> */
}
{
  /* <input className="header_searchInput" */
}
//  placeholder="dollarealms.com. what you want to buy" type="text" />
{
  /* <span className="header_searchIcon" onClick={srch}> */
}
{
  /* &#128269; */
}
{
  /* </span> */
}
{
  /* </span> */
}
{
  /* </Box> */
}
{
  /* </Grid> */
}
{
  /* <Grid item xs={12} lg={6}> */
}
{
  /* <Box className="header_nav"> */
}
{
  /* <Box sx={{ display: { xs: "block", sm: "none" } }}> */
}
{
  /* <Link to="/"> */
}
{
  /* <img className="header_logo" src="../images/logo.jpg" alt="" /> */
}
{
  /* </Link> */
}
{
  /* </Box> */
}
{
  /* <Link to={!user && "/Login"}> */
}
{
  /* <div className="header_option" onClick={handleAuthentication}> */
}
{
  /* <span className="header_optionLineOne"> */
}
{
  /* Hello {!user ? "Guest" : user.email}{" "} */
}
{
  /* </span> */
}
{
  /* <span className="header_optionLineTwo"> */
}
{
  /* {user ? "Sing Out" : "Sign In"} */
}
{
  /* </span> */
}
{
  /* </div> */
}
{
  /* </Link> */
}
{
  /* <Link to="/orders"> */
}
{
  /* <div className="header_option"> */
}
{
  /* <span className="header_optionLineOne">Returns</span> */
}
{
  /* <span className="header_optionLineTwo">& Orders</span> */
}
{
  /* </div> */
}
{
  /* </Link> */
}
{
  /* <div className="header_option"> */
}
////   <span className="header_optionLineOne">Your</span>
//   <span className="header_optionLineTwo"> Prime</span>
{
  /* </div> */
}
{
  /* <Link to="Checkout"> */
}
{
  /* <div className="header_optionBasket"> */
}
{
  /* <span className="header_optionLineTwo header_basketCount"> */
}
{
  /* {basket?.length} */
}
{
  /* </span> */
}
{
  /* <span className="basket"> */
}
{
  /* <ShoppingCartOutlinedIcon sx={{ margin: 0, padding: 0 }} /> */
}
{
  /* </span> */
}
// the auth user is not null display Sign Out  :
{
  /*  */
}
//   ?. optional chaining is to skip any errors, eg due to null value
{
  /* </div> */
}
{
  /* </Link> */
}
{
  /* </Box> */
}
{
  /* </Grid> */
}
{
  /* </Grid> */
}
{
  /* </ThemeProvider> */
}
// );
// }
//
// export default Header;
//d:\WORK IN PROGRESS\NewEcommerce\website\src\productsdb.json
