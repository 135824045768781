import React from "react";
import "./Product.css";
import { useStateValue } from "./StateProvider";
import Returns from "./Returns";
import Detail from "./Detail";
import Shipping from "./Shipping";
import Pricing from "./Pricing";
function Product({ id, title, image, price, rating }) {
  // to pull data from datalayer
  const [{ basket }, dispatch] = useStateValue(); //  <== if we distructure the state to get the basket

  //const [state , dispatch ] = useStateValue();        // state is the state of the global store

  //console.log('Produc.js:::this is the basket >>> ', basket);

  // lets dispatch the items selected
  const addToBasket = (event) => {
    event.preventDefault();
    // dispatching the item into the data layer
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        // id: id,
        id: basket?.length + 1,
        title: title,
        image: image,
        rating: rating,
        price: price,
      },
    });
  };
  return (
    <div className="product">
      <img src={image} alt="prd" />

      <div className="product_info" key={id}>
        <h6>{title}</h6>
        <div className="product_rating">
          {Array(rating)
            .fill()
            .map((_, index) => {
              return (
                <p key={index} className="star_color">
                  &#9734;
                </p>
              );
            })}
        </div>
        <div className="product_price">
          <small>$</small>
          <strong>{price}</strong>
        </div>
      </div>

      <button onClick={addToBasket}>Add to Cart</button>
    </div>
  );
}

export default Product;
