import * as React from "react";
import { DialogTitle } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { Box, Link, Typography } from "@mui/material";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={50} ref={ref} variant="filled" {...props} />;
});
export default function PolicyNoticeV2() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <label
        style={{
          height: 20,
          width: 100,
          justifyItems: "center",
          color: "gray",
          textDecoration: "none",
          "&:hover": {
            cursor: "pointer",
            color: "white",
          },
        }}
        onClick={handleClickOpen}
      >
        PolicyNotices
      </label>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>dollareamls.com</DialogTitle> <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <Box>
            <Typography variant="h3" component="div" gutterBottom>
              Dollarealms Privacy Notice
            </Typography>
            <Typography
              variant="h6"
              fontWeight="700"
              component="div"
              gutterBottom
            >
              August 24, 2023
              <hr />
            </Typography>
            <Typography variant="body2">
              <p>
                We know that you care how information about you is used and
                <br />
                shared, and we appreciate your trust that we will do so
                carefully and sensibly. This Privacy Notice describes how
                <br />
                Dollarealms and its affiliates (collectively "Dollarealms")
                collect and process your personal information through
                <br />
                Dollarealms websites, devices, products, services, online and
                physical stores.
                <b>
                  <br />
                  By using Dollarealms Services, you are consenting to the
                  practices described in this Privacy Notice.
                  <br />
                  <br />
                </b>
              </p>
              <p>
                <ul>
                  <li>
                    What Personal Information About Customers Does Dollarealms
                  </li>
                  <li>
                    Collect? For What Purposes Does Dollarealms Use Your
                    Personal
                  </li>
                  <li>
                    Information? What About Cookies and Other Identifiers? Does
                  </li>
                  <li>
                    Dollarealms Share Your Personal Information? How Secure Is
                  </li>
                  <li>
                    Information About Me? What About Advertising? What
                    Information
                  </li>
                  <li>
                    Can I Access? What Choices Do I Have? Are Children Allowed
                    to
                  </li>
                  <li>
                    Use Dollarealms Services? EU-US and Swiss-US Data Privacy
                  </li>
                  <li>
                    Framework Additional State-Specific Privacy Disclosures
                  </li>
                  <li>
                    Conditions of Use, Notices, and Revisions Related Practices
                  </li>
                  <li>
                    and Information Examples of Information Collected What
                  </li>
                  <li>
                    Personal Information About Customers Does Dollarealms
                    Collect?{" "}
                  </li>
                </ul>
              </p>
              <br />
            </Typography>
            <Typography
              variant="h4"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              What Personal Information About Customers Does Dollarealms
              Collect?
            </Typography>
            <Typography variant="body2">
              <p>
                We collect your personal information in order to provide and
                continually improve our products and services.
              </p>
              <br />
              <p>Here are the types of personal information we collect:</p>
              <br />
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>
                  <b>Information You Give Us:</b>
                  <br />
                  We receive and store any information you provide in relation
                  to Dollarealms Services. Click here to see examples of what
                  <br />
                  we collect. You can choose not to provide certain information,
                  but then you might not be able to take
                  <br />
                  advantage of many of our Dollarealms Services.
                </li>
                <br />
                <li>
                  <b>Automatic Information:</b>
                  <br />
                  We automatically collect and store certain types of
                  information about your use of Dollarealms Services,
                  <br />
                  including information about your interaction with products,
                  content, and services available through Dollarealms
                  <br />
                  Services. Like many websites, we use "cookies" and other
                  unique identifiers, and we obtain certain types of
                  <br />
                  information when your web browser or device accesses
                  Dollarealms Services and other content served by or on
                  <br />
                  behalf of Dollarealms on other websites. Our physical stores
                  may use cameras, computer vision, sensors, and other
                  <br />
                  technology to gather information about your activity in the
                  store, such as the products and services you interact with.
                  <br />
                  Click here to see examples of what we collect.
                </li>
                <br />
                <li>
                  <b> Information from Other Sources:</b> <br />
                  We might receive information about you from other sources,
                  such as updated delivery and address information from our
                  <br />
                  carriers, which we use to correct our records and deliver your
                  next purchase more easily. Click here to see additional
                  <br />
                  examples of the information we receive.
                </li>
                <br />
                <li> </li> <br />
              </ul>
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              For What Purposes Does Dollarealms Use Your Personal Information?
            </Typography>
            <Typography variant="body2">
              <p>
                <br />
                We use your personal information to operate, provide, develop,
                and improve the products and services that we offer our
                <br />
                customers. These purposes include:
                <br />
                <br />
              </p>
              <ul>
                <li>
                  <b>Purchase and delivery of products and services. </b>
                  We use your personal information to take and handle orders,
                  <br />
                  deliver products and services, process payments, and
                  communicate with you about orders, products and services, and
                  promotional offers.
                </li>
                <li>
                  <b>
                    Provide, troubleshoot, and improve Dollarealms Services.
                  </b>
                  We use your personal information to provide functionality,
                  <br />
                  analyze performance, fix errors, and improve the usability
                  <br />
                  and effectiveness of the Dollarealms Services. . We use your
                  personal information to recommend features, products, and
                  <br />
                  services that might be of interest to you, identify your
                  preferences, and personalize your experience with Dollarealms
                  Services.
                </li>
                <li>
                  <b>Provide voice, image and camera services. </b>When you use
                  our voice, image and camera services, we use your voice
                  <br />
                  input, images, videos, and other personal information to
                  respond to your requests, provide the requested service to
                  <br />
                  you, and improve our services.
                </li>
                <li>
                  <b>Comply with legal obligations. </b>In certain cases, we
                  collect and use your personal information to comply with
                  <br />
                  laws. For instance, we collect from sellers information
                  regarding place of establishment and bank account
                  <br />
                  information for identity verification and other purposes.
                </li>
                <li>
                  <b>Communicate with you. </b>We use your personal information
                  to communicate with you in relation to
                  <br />
                  Dollarealms Services via different channels (e.g., by phone,
                  email, chat).
                </li>
                <li>
                  <b>Advertising. </b>We use your personal information to
                  display interest-based ads for features, products, and
                  <br />
                  services that might be of interest to you. We do not use
                  information that personally identifies you to display
                  <br />
                  interest-based ads. To learn more, please read our
                  <Link> Interest-Based Ads notice.</Link>
                </li>
                <li>
                  <b>Fraud Prevention and Credit Risks. </b>We use personal
                  information to prevent and detect fraud and abuse in order
                  <br />
                  to protect the security of our customers, Dollarealms , and
                  others. We may also use scoring methods to assess and manage
                  credit risks.
                </li>
              </ul>
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              {" "}
              <br />
              What About Cookies and Other Identifiers?
              <br />
            </Typography>
            <Typography variant="body2" className="textBody">
              To enable our systems to recognize your browser or device and to
              provide and improve Dollarealms Services, we use cookies <br />
              and other identifiers. For more information about cookies and how
              we use them, please read our <Link>Cookies Notice.</Link>
              <br />
              <p />
              <br />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              Does Dollarealms Share Your Personal Information?
            </Typography>
            <Typography variant="body2" className="textBody">
              Information about our customers is an important part of our
              business, and we are not in the business of selling our customers'
              personal information to others.
              <br /> We share customers' personal information only as described
              below .
              <br />
              <br />
              <ul>
                <li>
                  <b>Transactions involving Third Parties:</b> We make available
                  to you services, products, applications, or skills
                  <br />
                  provided by third parties for use on or through Dollarealms
                  Services. For example, you can order products from third
                  <br />
                  parties through our stores, download applications from
                  third-party application providers from our App Store, and
                  <br />
                  enable third-party skills through our Alexa services. We also
                  offer services or sell product lines jointly with
                  <br />
                  third-party businesses, such as co-branded credit cards. You
                  can tell when a third party is involved in your
                  <br />
                  transactions, and we share customers' personal information
                  related to those transactions with that third party. <br />
                  <br />
                </li>
                <li>
                  <b>Third-Party Service Providers:</b>We employ other companies
                  and individuals to perform functions on our <br />
                  behalf. Examples include fulfilling orders for products or
                  services, delivering packages, sending postal mail and <br />
                  email, removing repetitive information from customer lists,
                  analyzing data, providing marketing assistance, providing{" "}
                  <br />
                  search results and links (including paid listings and links),
                  processing payments, transmitting content, scoring, <br />
                  assessing and managing credit risk, and providing customer
                  service. These third-party service providers have access to{" "}
                  <br />
                  personal information needed to perform their functions, but
                  may not use it for other purposes.
                  <br />
                  <br />
                </li>
                <li>
                  <b>Business Transfers:</b>As we continue to develop our
                  business, we might sell or buy other businesses or services.
                  <br />
                  In such transactions, customer information generally is one of
                  the transferred business assets but remains subject to
                  <br />
                  the promises made in any pre-existing Privacy Notice (unless,
                  of course, the customer consents otherwise). Also,
                  <br />
                  in the unlikely event that Dollarealms.com, Inc. or
                  substantially all of its assets are acquired, customer
                  information will of
                  <br />
                  course be one of the transferred assets.
                  <br />
                  <br />
                </li>
                <li>
                  <b>Protection of Dollarealms and Others:</b>We release account
                  and other personal information when we believe release is
                  <br />
                  appropriate to comply with the law; enforce or apply our
                  Conditions of Use and other agreements; or protect the
                  <br />
                  rights, property, or safety of Dollarealms, our users, or
                  others. This includes exchanging information with other
                  companies
                  <br />
                  and organizations for fraud protection and credit risk
                  reduction.
                  <br />
                  <br />
                </li>
              </ul>
              Other than as set out above, you will receive notice when personal
              information about you might be shared with third parties, <br />
              and you will have an opportunity to choose not to share the
              information
            </Typography>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              How Secure Is Information About Me?
            </Typography>
            <Typography variant="body2">
              We design our systems with your security and privacy in mind.
              <br /> <br />
              <ul>
                <li>
                  We work to protect the security of your personal information
                  during transmission by using encryption protocols and
                  software.
                </li>
                <li>
                  We follow the Payment Card Industry Data Security Standard
                  (PCI DSS) when handling credit card data.
                </li>
                <li>
                  We maintain physical, electronic, and procedural safeguards in
                  connection with the collection, storage, and disclosure
                  <br />
                  of customer personal information. Our security procedures mean
                  that we may ask to verify your identity before we disclose
                  personal information to you.
                </li>
                <li>
                  Our devices offer security features to protect them against
                  unauthorized access and loss of data. You can control these
                  <br />
                  features and configure them based on your needs. Click here
                  for more information on how to manage the security settings of
                  your device.
                </li>
                <li>
                  It is important for you to protect against unauthorized access
                  to your password and to your computers, devices, and
                  <br />
                  applications. We recommend using a unique password for your
                  Dollarealms account that is not used for other online
                  accounts.
                  <br />
                  Be sure to sign off when finished using a shared computer.
                  Click here for more information on how to sign off.
                </li>
              </ul>
              <br />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              What About Advertising?
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>
                  <b>Third-Party Advertisers and Links to Other Websites: </b>
                  Dollarealms Services may include third-party advertising and
                  <br />
                  links to other websites and apps. Third-party advertising
                  partners may collect information about you when you interact
                  <br />
                  with their content, advertising, and services. For more
                  information about third-party advertising at Dollarealms,
                  <br />
                  including <Link>interest-based ads</Link>, please read our
                  Interest-Based Ads notice. To adjust your advertising
                  preferences, please
                  <br />
                  go to the <Link>Your Ads Privacy Choices page.</Link>
                </li>
                <li>
                  <b>Use of Third-Party Advertising Services: </b>We provide ad
                  companies with information that allows them to serve you
                  <br />
                  with more useful and relevant Dollarealms ads and to measure
                  their effectiveness. We never share your name or other
                  <br />
                  information that directly identifies you when we do this.
                  Instead, we use an advertising identifier like a cookie, a
                  <br />
                  device identifier, or a code derived from applying
                  irreversible cryptography to other information like an email
                  <br />
                  address. For example, if you have already downloaded one of
                  our apps, we will share your advertising identifier and data
                  about that event so that you will not be served an ad to
                  download the app again. While we do not share your specific
                  <br />
                  shopping actions like purchases, product views, or searches
                  with ad companies, we may share an advertising identifier
                  <br />
                  and an estimate of the value of the ads they show you on our
                  behalf so they can serve you with more effective Dollarealms
                  ads.
                  <br />
                  Some ad companies also use this information to serve you
                  relevant ads from other advertisers. You can learn more
                  <br />
                  about how to opt-out of personalized advertising (such as
                  cross-context behavioral advertising) by going to the{" "}
                  <Link>Your Ads Privacy Choices page.</Link>
                </li>
              </ul>
              <br />
              <br />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              What Information Can I Access?
            </Typography>
            <Typography variant="body2">
              You can access your information, including your name, address,
              payment options, profile information, Prime membership, <br />
              <br />
              household settings, and purchase history in the "Your Account"
              section of the website. Click <Link>here</Link> for a list of{" "}
              <br />
              examples that you can access.
              <br />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              What Choices Do I Have?
            </Typography>
            <Typography variant="body2">
              <p>
                If you have any questions as to how we collect and use your
                personal information, please contact our{" "}
                <Link>Customer Service.</Link>
                <br />
                Many of our Dollarealms Services also include settings that
                provide you with options as to how your information is being
                used. <br />
              </p>
              <br />
              <ul>
                <li>
                  As described above, you can choose not to provide certain
                  information, but then you might not be able to take advantage
                  of many of the Dollarealms Services.
                  <br />
                </li>
                <li>
                  You can add or update certain information on pages such as
                  those referenced in
                  <Link> What Information Can I Access?.</Link> When you <br />
                  update information, we usually keep a copy of the prior
                  version for our records.
                  <br />
                </li>
                <li>
                  If you do not want to receive email or other communications
                  from us, please adjust your
                  <Link> Customer Communication Preferences.</Link>
                  <br /> If you don't want to receive in-app notifications from
                  us, please adjust your notification settings in the app or
                  device.
                  <br />
                </li>
                <li>
                  You may adjust your personalized advertising preferences by
                  visiting<Link> Your Ads Privacy </Link>Choices
                  <br />
                </li>
                <li>
                  The Help feature on most browsers and devices will tell you
                  how to prevent your browser or device from accepting new{" "}
                  <br />
                  cookies or other identifiers, how to have the browser notify
                  you when you receive a new cookie, or how to block cookies{" "}
                  <br />
                  altogether. Because cookies and identifiers allow you to take
                  advantage of some essential features of Dollarealms <br />
                  Services, we recommend that you leave them turned on. For
                  instance, if you block or otherwise reject our cookies, <br />
                  will not be able to add items to your Shopping Cart, proceed
                  to Checkout, or use any Services that require you to Sign in.
                  For more information about cookies and other <br />
                  identifiers, see our <Link>Cookies Notice.</Link>
                  <br />
                </li>
                <li>
                  If you want to browse our websites without linking the
                  browsing history to your account, you may do so by logging{" "}
                  <br />
                  out of your account<Link> here </Link>and blocking cookies on
                  your browser.
                  <br />
                </li>
                <li>
                  You can manage the recommendations you receive in our store
                  <Link> here</Link>, remove recommendations you don’t want to
                  see<Link> here by</Link>
                  <br />
                  selecting View All and Manage then selecting the Remove Items
                  toggle that appears at the top of the page, and edit your
                  browsing history<Link> here.</Link>
                  <br />
                </li>
                <li>
                  You will also be able to opt out of certain other types of
                  data usage by updating your settings on the applicable <br />
                  Dollarealms website (e.g., in "Manage Your Content and
                  Devices"), device, or application. For more information click
                  <Link> here.</Link>
                  <br />
                  Most non-Dollarealms devices also provide users with the
                  ability to change device permissions (e.g., disable/access
                  location <br />
                  services, contacts). For most devices, these controls are
                  located in the device's settings menu. If you have questions{" "}
                  <br />
                  about how to change your device permissions on devices
                  manufactured by third parties, we recommend you contact your{" "}
                  <br />
                  mobile service carrier or your device manufacturer.
                  <br />
                </li>
                <li>
                  If you are a seller, you can add or update certain information
                  in<Link> Seller Central</Link>, update your account <br />
                  information by accessing your
                  <Link> Seller Account Information</Link>, and adjust your
                  email or other communications you receive <br />
                  from us by updating your
                  <Link> Notification Preferences.</Link>
                  <br />
                </li>
                <li>
                  If you are an author, you can add or update the information
                  you have provided in the<Link> Author Portal </Link>and Author
                  Central by <br />
                  accessing your accounts in the Author Portal and
                  <Link> Author Central</Link>, respectively.
                  <br />
                </li>
                <li>
                  If you shop in our physical stores, you can manage how certain
                  data is used to analyze the performance of our <br />
                  products and services, as well as learn more
                  <Link> here </Link>
                  <br />
                </li>
              </ul>
              <p>
                In addition, to the extent required by applicable law, you may
                have the right to request access to or delete your personal{" "}
                <br />
                information. If you wish to do any of these things, you may go
                to<Link> Data Privacy Queries</Link>. Depending on your data
                choices,
                <br />
                certain services may be limited or unavailable.
              </p>
              <br />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              Are Children Allowed to Use Dollarealms Services?
            </Typography>
            <Typography variant="body2">
              <br />
              <p>
                Dollarealms does not sell products for purchase by children. We
                sell children's products for purchase by adults. If you are{" "}
                <br />
                under 18, you may use Dollarealms Services only with the
                involvement of a parent or guardian. We do not knowingly <br />
                collect personal information from children under the age of 13
                without the consent of the child's parent or guardian. For{" "}
                <br />
                more information, please see our
                <Link> Children's Privacy Disclosure</Link>
              </p>
              <br />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              Additional State-Specific Privacy Disclosures
            </Typography>
            <Typography variant="body2">
              <br />
              <p>
                Click<Link> here </Link>to read additional state-specific
                privacy disclosures as required under applicable laws
              </p>
              <br />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              Conditions of Use, Notices, and Revisions
            </Typography>
            <Typography variant="body2">
              <br />
              <p>
                If you choose to use Dollarealms Services, your use and any
                dispute over privacy is subject to this Notice and our
                <br />
                <Link>Conditions of Use</Link> , including limitations on
                damages, resolution of disputes, and application of the law of
                <br />
                the state of Washington. If you have any concern about privacy
                at Dollarealms, please contact us with a thorough description,
                and
                <br />
                we will try to resolve it. Our business changes constantly, and
                our Privacy Notice will change also. You should check our
                <br />
                websites frequently to see recent changes. Unless stated
                otherwise, our current Privacy Notice applies to all
                <br />
                information that we have about you and your account. We stand
                behind the promises we make, however, and will never
                <br />
                materially change our policies and practices to make them less
                protective of customer information collected in the past without
                the consent of affected customers
              </p>
              <br />
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              Examples of Information Collected
            </Typography>
            <Typography variant="body2">
              <p>
                <b>Information You Give Us When You Use Dollarealms Services</b>
              </p>
              <br />
              You provide information to us when you:
              <br />
              <p>
                <ul>
                  <li>
                    search or shop for products or services in our stores;
                  </li>
                  <li>
                    add or remove an item from your cart, or place an order
                    through or use Dollarealms Services;
                  </li>
                  <li>
                    download, stream, view, or use content on a device or
                    through a service or application on a device;
                  </li>
                  <li>
                    provide information in Your Account (and you might have more
                    than one if you have used more than one email address or
                    mobile number when shopping with us) or Your Profile ;
                  </li>
                  <li>upload your contacts;</li>
                  <li>
                    configure your settings on, provide data access permissions
                    for, or interact with an Dollarealms device or service;
                  </li>
                  <li>
                    provide information in your Seller Account , Kindle Direct
                    Publishing account , Developer account , or any other
                    account we make available that allows you to develop or
                    offer software, goods, or services to Dollarealms customers;
                  </li>
                  <li>
                    offer your products or services on or through Dollarealms
                    Services;
                  </li>
                  <li>communicate with us by phone, email, or otherwise;</li>
                  <li>
                    complete a questionnaire, a support ticket, or a contest
                    entry form;
                  </li>
                  <li>
                    upload or stream images, videos or other files to Prime
                    Photos, Dollarealms Drive, or other Dollarealms Services;
                  </li>
                  <li>use our services such as Prime Video;</li>
                  <li>
                    compile Playlists, Watchlists, Wish Lists, or other gift
                    registries;
                  </li>
                  <li>
                    participate in Discussion Boards or other community
                    features;
                  </li>
                  <li>provide and rate Reviews;</li>
                  <li>specify a Special Occasion Reminder; or</li>
                  <li>
                    employ Product Availability Alerts, such as Available to
                    Order Notifications.
                  </li>
                </ul>
              </p>
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              As a result of those actions, you might supply us with such
              information as:
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>
                  identifying information such as your name, address, and phone
                  numbers;
                </li>
                <li>payment information;</li>
                <li>your age;</li>
                <li>your location information;</li>
                <li>your IP address;</li>
                <li>
                  people, addresses and phone numbers listed in your Addresses;
                </li>
                <li>email addresses of your friends and other people;</li>
                <li>content of reviews and emails to us;</li>
                <li>personal description and photograph in Your Profile;</li>
                <li>voice recordings when you speak to Alexa;</li>
                <li>
                  images and videos collected or stored in connection with
                  Dollarealms Services;
                </li>
                <li>
                  information and documents regarding identity, including Social
                  Security and driver's license numbers;
                </li>
                <li>corporate and financial information;</li>
                <li>credit history information; and</li>
                <li>
                  device log files and configurations, including Wi-Fi
                  credentials, if you choose to automatically synchronize them
                  with your other Dollarealms devices.
                </li>
              </ul>
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              Automatic Information
            </Typography>
            <Typography variant="body2">
              <br />
              Examples of the information we collect and analyze include:
              <br />
              <ul>
                <li>
                  the internet protocol (IP) address used to connect your
                  computer to the internet;
                </li>
                <li>login, email address, and password;</li>
                <li>the location of your device or computer;</li>
                <li>
                  content interaction information, such as content downloads,
                  streams, and playback details, including duration and number
                  of simultaneous streams and downloads, and network details for
                  streaming and download quality, including information about
                  your internet service provider;
                </li>
                <li>
                  device metrics such as when a device is in use, application
                  usage, connectivity data, and any errors or event failures;
                </li>
                <li>
                  Dollarealms Services metrics (e.g., the occurrences of
                  technical errors, your interactions with service features and
                  content, your settings preferences and backup information,
                  location of your device running an application, information
                  about uploaded images and files such as the file name, dates,
                  times and location of your images);
                </li>
                <li>version and time zone settings</li>
                <li>
                  purchase and content use history, which we sometimes aggregate
                  with similar information from other customers to create
                  features like Top Sellers;
                </li>
                <li>
                  the full Uniform Resource Locator (URL) clickstream to,
                  through, and from our websites, including date and time;
                  products and content you viewed or searched for; page response
                  times, download errors, length of visits to certain pages, and
                  page interaction information (such as scrolling, clicks, and
                  mouse-overs);
                </li>
                <li>
                  phone numbers used to call our customer service number; and
                </li>
                <li>
                  images or videos when you shop in our stores, or stores using
                  Dollarealms Services.
                </li>
                <br />
                We may also use device identifiers, cookies, and other
                technologies on devices, applications, and our web pages to
                collect browsing, usage, or other technical information.
                <br />
              </ul>
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              Information from Other Sources
            </Typography>
            <Typography variant="body2">
              <br />
              Examples of information we receive from other sources include:
              <br />
              <br />
              <p>
                <ul>
                  <li>
                    updated delivery and address information from our carriers
                    or other third parties, which we use to correct our
                    <br />
                    records and deliver your next purchase or communication more
                    easily;
                    <br />
                  </li>
                  <li>
                    account information, purchase or redemption information, and
                    page-view information from some merchants with which
                    <br />
                    we operate co-branded businesses or for which we provide
                    technical, fulfillment, advertising, or other services;
                    <br />
                  </li>
                  <li>
                    information about your interactions with products and
                    services offered by our subsidiaries;
                    <br />
                  </li>
                  <li>
                    search results and links, including paid listings (such as
                    Sponsored Links);
                    <br />
                  </li>
                  <li>
                    information about internet-connected devices and services
                    and
                    <br />
                  </li>
                  <li>
                    credit history information from credit bureaus, which we use
                    to help prevent and detect fraud and to offer certain
                    <br />
                    credit or financial services to some customers
                    <br />
                  </li>
                </ul>
              </p>
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ marginTop: "20px", fontWeight: 700 }}
            >
              Information You Can Access
            </Typography>
            <Typography
              variant="body2"
              component="div"
              sx={{ marginTop: "20px" }}
            >
              <p>
                Examples of information you can access through Dollarealms
                Services include:
                <br /> <br />
                <ul>
                  <li>
                    status of recent orders (including subscriptions);
                    <br />
                  </li>
                  <li>
                    your complete order history;
                    <br />
                  </li>
                  <li>
                    personally identifiable information (including name, email,
                    password, and address book);
                    <br />
                  </li>
                  <li>
                    payment settings (including payment card information,
                    promotional certificate and gift card balances, and 1-Click
                    settings);
                    <br />
                  </li>
                  <li>
                    email notification settings (including Product Availability
                    Alerts, Delivers, Special Occasion Reminders and
                    newsletters);
                    <br />
                  </li>
                  <li>
                    recommendations and the products you recently viewed that
                    are the basis for recommendations (including Recommended for
                    You and Improve Your Recommendations);
                    <br />
                  </li>
                  <li>
                    shopping lists and gift registries (including Wish Lists and
                    Baby and Wedding Registries);
                    <br />
                  </li>
                  <li>
                    your content, devices, services, and related settings, and
                    communications and personalized advertising preferences
                    <br />
                  </li>
                  <li>
                    content that you recently viewed;
                    <br />
                  </li>
                  <li>
                    voice recordings associated with your account;
                    <br />
                  </li>
                  <li>
                    Your Profile (including your product Reviews,
                    Recommendations, Reminders and personal profile);
                    <br />
                  </li>
                  <li>
                    If you are a seller, you can access your account and other
                    information, and adjust your communications preferences,{" "}
                    <br />
                    by updating your account in Seller Central.
                    <br />
                  </li>
                  <li>
                    If you are an author, you can access your account and other
                    information, and update your accounts, on the Kindle <br />
                    Direct Publishing (KDP) or Author Central website, as
                    applicable.
                    <br />
                  </li>
                  <li>
                    If you are a developer participating in our Developer
                    Services Program, you can access your account and other{" "}
                    <br />
                    information, and adjust your communications preferences, by
                    updating your accounts in the Developer Services Portal.
                    <br />
                  </li>
                </ul>
              </p>
            </Typography>
          </Box>
        </DialogContent>
        <hr />
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          We are always here for you
        </Alert>
      </Snackbar>
    </Stack>
  );
}
