import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./EmailForm.css";
const EmailForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Your Emailjs service ID, template ID, and public Key
    const serviceId = "service_cxnb3fh";
    const templateId = "template_ztyk5gj";
    const publicKey = "F294h0SsjLIVCSZtx";

    // create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Customer Service",
      to_email: "dollarealms@gmail.com",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        //console.log("Email sent successfully", response);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email", error);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="emailForm"
      width="100%"
      style={{ alignItems: "left", fontSize: "2rem", marginTop: "50px" }}
    >
      <div style={{ marginLeft: "20px", marginTop: "10px" }}>
        <h1>Customer Service</h1>
        <br />
        We will be glad to assist
        <p style={{ width: "90%", fontSize: "1rem", margin: "20px" }}>
          Please explain in detail what the matter is
        </p>
        <input
          style={{
            width: "55%",
            height: "30px",
            marginLeft: "7px",
            marginTop: "10px",
          }}
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          style={{ width: "55%", height: "30px", marginLeft: "7px" }}
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          style={{ width: "90%", marginLeft: "7px" }}
          placeholder="Explain What you are contacting us for"
          cols="50"
          rows="10"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button
          type="submit"
          style={{
            height: "40px",
            width: "90%",
            marginLeft: "7px",
            marginBottom: "30px",
          }}
        >
          Send
        </button>
      </div>
    </form>
  );
};
export default EmailForm;
