// reducer reduces the store items by putting selected items into our dataLayer/basket
//
export const initialState = {
  basket: [],
  user: null,
}; /* initial state of the dataLayer or reducer*/

//SELECTOR   .... getBasketTotal to be    called from subTotal
export const getBasketTotal = (basket_from_data_layer) =>
  // Mathemathecal Reduction into a single whole value ===>> resolving all dollar amounts into a Single TotalValue :
  basket_from_data_layer?.reduce((amount, item) => item.price + amount, 0);
const reducer = (state, action) => {
  // this is the relationship between the dispatcher and reducer
  console.log(action);
  switch (action.type) {
    // LISTEN OUT FOR THE DIFFERENT CASES OF EVENTS CREATED BY dispatch()
    // EVENT CASE 1:
    case "ADD_TO_BASKET":
      // if(state.basket==null) break;
      return {
        ...state, // whatever the state was originaly
        basket: [...state.basket, action?.item], //change basket to whatever the
        //basket previously is , whatever the action previously was
      };

    // EVENT CASE 2:    // eg when user clicks "Buy Now" at checkout page we empty the basket
    case "EMPTY_BASKET": // we cannot leave items still inside the basket if payment has been made
      return {
        ...state, // keep what ever is in the sate
        basket: [], // change the basket to an empty array
      };

    case "REFRESH_BASKET": //
      return {
        basket: action.item, //
      };
    // EVENT CASE 3:
    case "REMOVE_FROM_BASKET":
      // ...state, // whatever the state was originaly
      const index = state.basket.findIndex(
        (basketItem) => basketItem.id === action.id
      ); //change basket to whatever the basket previously is , whatever the action previously was
      let newBasket = [...state.basket]; // make a copy of the current state of basket
      if (index >= 0) {
        //then item was found in the basket
        newBasket.splice(index, 1); //delete
      } else {
        console.warn(`cant remove product (id:${action.id}) 
                                                    as it is not in the basket`);
      }

      return {
        ...state, // return the current state
        basket: newBasket,
      };

    // EVENT CASE 4:
    case "SET_USER":
      return {
        ...state, // retrieve everything in the state
        user: action.user, // and then update the user obj
      };

    default:
      return state; // default case
  }
};

export default reducer;
