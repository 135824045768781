import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import { addDoc, getDocs } from "firebase/firestore";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAepSACgsOIp_cmGu9sboG3VGbTc0ep0M4",
//   authDomain: "salesdol.firebaseapp.com",
//   projectId: "salesdol",
//   storageBucket: "salesdol.appspot.com",
//   messagingSenderId: "407278183243",
//   appId: "1:407278183243:web:c5051add523448cf7e3ece",
// };

const firebaseConfig = {
  apiKey: "AIzaSyBVuC-vGYnp7UgWm2cuNojNvA2k1yXA4FI",
  authDomain: "dollarealmsales.firebaseapp.com",
  projectId: "dollarealmsales",
  storageBucket: "dollarealmsales.appspot.com",
  messagingSenderId: "37358566836",
  appId: "1:37358566836:web:744b2640b25d7d78eb06e1",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };
export const auth = getAuth(app);

// const firebaseConfig = {
//   apiKey: "AIzaSyBjoL1fJ2a6L3GS2RaxOWZ8DDYIFcdfTHc",
//   authDomain: "dlsappdb.firebaseapp.com",
//   projectId: "dlsappdb",
//   storageBucket: "dlsappdb.appspot.com",
//   messagingSenderId: "87488607030",
//   appId: "1:87488607030:web:cb119ccf9195c8e196f997",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyAepSACgsOIp_cmGu9sboG3VGbTc0ep0M4",
//   authDomain: "salesdol.firebaseapp.com",
//   projectId: "salesdol",
//   storageBucket: "salesdol.appspot.com",
//   messagingSenderId: "407278183243",
//   appId: "1:407278183243:web:c5051add523448cf7e3ece",
// };
// export const app = initializeApp(firebaseConfig);
// // Initialize Cloud Firestore and get a reference to the service

// const db = getFirestore(app);
// export { db };
// export const auth = getAuth(app);
