import "./Subtotal.css";
import React, { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "./StateProvider";
import { getBasketTotal } from "./reducer";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
function Subtotal() {
  // const history = useHistory();
  const navigate = useNavigate();
  const [checkOutLabel, setCheckOutLabel] = useState("");
  const [{ basket, user }, dispatch] = useStateValue(); // pull from data layer

  const handleAuthentication = () => {
    if (!user) {
      // if user not logged in
      setCheckOutLabel(
        `You must Login before \n you can proceed to check out !`
      );
      return;
    } else if (basket.length === 0) {
      setCheckOutLabel(`You must select the item(s) you want to check out !`);
      return;
    }
    // if we get here all is well
    navigate("/payment");
  };

  return (
    <div className="subtotal">
      <CurrencyFormat
        renderText={(
          value // see below code , value={  getBasketTotal ( basket )  }
        ) => (
          <>
            <p>
              Subtotal( {basket.length} items):<strong> {value} </strong>
            </p>
            <small className="subtotal__gift">
              <input type="checkbox" />
              Could this order be a gift
            </small>
          </>
        )}
        decimalScale={2}
        value={getBasketTotal(basket)} // getBasketTotal() is a function of the reducer.js
        displayType={"text"} //   configures the diplay of the <> Subtotal , checkbox </> above
        thousandSeparator={true}
        prefix={"$"}
      />
      {/* end of currency format */}

      <button onClick={handleAuthentication}>Proceed to Check out</button>
      <div className="checkOutLabel">{checkOutLabel}</div>
    </div>
  );
}

export default Subtotal;
