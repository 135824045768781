import * as React from "react";
import { DialogTitle } from "@mui/material";
import {Grid, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { blue } from "@mui/material/colors";

export default function ConditionsOfUseV2() {
  const [open, setOpen] = React.useState(false);
  const [isYes, setIsYes] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsYes(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Grid>
        <Grid item>
          <label
            style={{
              color: "gray",
              "&:hover": {
                cursor: "pointer",
                color: "white",
              },
            }}
            onClick={handleClickOpen}
          >
            Conditions Of Use
          </label>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>dollarealms.com</DialogTitle>
            <hr />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <br />
                <Typography variant="h3" component="div" gutterBottom>
                  Conditions of Use
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  component="div"
                  gutterBottom
                >
                  August 24, 2023
                  <hr />
                </Typography>
                <Typography variant="body2">
                  <p>
                    Welcome to Dollarealms.com. Dollarealms LLC and/or its
                    affiliates ("Dollarealms") provide website <br />
                    features and other products and services to you when you
                    visit or shop at Dollarealms.com, use Dollarealms
                    <br />
                    products or services, use Dollarealms applications for
                    mobile, or use software provided by Dollarealms <br />
                    in connection with any of the foregoing (collectively,
                    "Dollarealms Services"). By using the <br />
                    Dollarealms Services, you agree, on behalf of yourself and
                    all members of your household and others <br />
                    who use any Service under your account, to the following
                    conditions.
                  </p>
                  <br />
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  component="div"
                  gutterBottom
                >
                  <p>Please read these conditions carefully.</p>
                </Typography>
                <Typography variant="body2">
                  We offer a wide range of Dollarealms Services, and sometimes
                  additional terms may apply. When you use a Dollarealms Service
                  <br />
                  (for example, Your Profile, Dollarealms applications) you also
                  will be subject to the guidelines, terms and agreements
                  <br />
                  applicable to that Dollarealms Service ("Service Terms"). I
                  these Conditions of Use are inconsistent with the Service
                  Terms,
                  <br />
                  those Service Terms will control.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  PRIVACY
                </Typography>
                <Typography variant="body2">
                  <p>
                    Please review our Privacy Notice, which also governs your
                    use of Dollarealms Services, to understand our practices
                  </p>
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  ELECTRONIC COMMUNICATIONS
                </Typography>
                <Typography variant="body2">
                  When you use Dollarealms Services, or send e-mails, text
                  messages, and other communications from your desktop or mobile
                  device to us,
                  <br />
                  you may be communicating with us electronically. You consent
                  to receive communications from us electronically, such as
                  e-mails, texts, <br />
                  mobile push notices, or notices and messages on this site or
                  through the other Dollarealms Services, such as our Message
                  <br />
                  Center, and you can retain copies of these communications for
                  your records. You agree that all agreements, notices,
                  disclosures, and
                  <br />
                  other communications that we provide to you electronically
                  satisfy any legal requirement that such communications be in
                  writing.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  COPYRIGHT
                </Typography>
                <Typography variant="body2">
                  All content included in or made available through any
                  Dollarealms Service, such as text, graphics, logos, button
                  icons,
                  <br /> images, audio clips, digital downloads, data
                  compilations, and software is <br />
                  the property of Dollarealms or its content suppliers and
                  protected by United States and international copyright laws.
                  <br />
                  The compilation of all content included in or made available
                  through any Dollarealms Service is the exclusive <br />
                  property of Dollarealms and protected by U.S. and
                  international copyright laws.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  TRADEMARKS
                </Typography>
                <Typography variant="body2">
                  <p className="textBody">
                    Click here to see a non-exhaustive list of Dollarealms
                    trademarks. In addition, graphics, logos, page headers,
                    button <br />
                    icons, scripts, and service names included in or made
                    available through any Dollarealms Service are trademarks or
                    trade dress of
                    <br />
                    Dollarealms in the U.S. and other countries. Dollarealms'
                    trademarks and trade dress may not be used in connection
                    with
                    <br />
                    any product or service that is not Dollarealms', in any
                    manner that is likely to cause confusion among customers, or
                    in any
                    <br />
                    manner that disparages or discredits Dollarealms .<br /> All
                    other trademarks not owned by Dollarealms that appear in any
                    Dollarealms Service are the property of their respective
                    owners,
                    <br />
                    who may or may not be affiliated with, connected to, or
                    <br />
                    sponsored by Dollarealms .
                  </p>
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  gutterBottom
                  sx={{ marginTop: "20px" }}
                >
                  LICENSE AND ACCESS
                </Typography>
                <Typography variant="body2" className="textBody">
                  Subject to your compliance with these Conditions of Use and
                  any Service Terms, and your payment of any applicable fees,
                  <br />
                  Dollarealms or its content providers grant you a limited,
                  non-exclusive, non-transferable, non-sublicensable license to
                  <br />
                  access and make personal and non-commercial use of the
                  Dollarealms Services. This license does not include any resale
                  or <br />
                  commercial use of any Dollarealms Service, or its contents;
                  any collection and use of any product listings, descriptions,
                  or prices; any
                  <br />
                  derivative use of any Dollarealms Service or its contents; any
                  downloading, copying, or other use of account information for
                  the
                  <br />
                  benefit of any third party; or any use of data mining, robots,
                  or similar data gathering and extraction tools. All rights not
                  <br />
                  expressly granted to you in these Conditions of Use or any
                  Service Terms are reserved and retained by Dollarealms or its
                  licensors,
                  <br />
                  suppliers, publishers, rightsholders, or other content
                  providers. No Dollarealms Service, nor any part of any
                  Dollarealms Service,
                  <br />
                  may be reproduced, duplicated, copied, sold, resold, visited,
                  or otherwise exploited for any commercial purpose without
                  express
                  <br />
                  written consent of Dollareams. You may not frame or utilize
                  framing techniques to enclose any trademark, logo, or other
                  <br />
                  proprietary information (including images, text, page layout,
                  or form) of Dollarealms on without express written consent.
                  You may
                  <br />
                  not use any meta tags or any other "hidden text" utilizing
                  Dollarealms' name or trademarks without the express written
                  consent of <br />
                  Dollarealms . You may not misuse the Dollarealms Services. You
                  may use the Dollarealms Services only as permitted
                  <br />
                  by law. The licenses granted by Dollarealms terminate if you
                  do not comply with these Conditions of Use or any Service
                  Terms.
                  <br />
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  gutterBottom
                  sx={{ marginTop: "20px" }}
                >
                  YOUR ACCOUNT
                </Typography>
                <Typography variant="body2">
                  You may need your own Dollarealms account to use certain
                  Dollarealms Services, and you may be required to be logged in
                  to
                  <br />
                  the account and have a valid payment method associated with
                  it. If there is a problem charging your selected payment
                  method, we may
                  <br />
                  charge any other valid payment method associated with your
                  account. Visit Your Payments to manage your payment options.
                  You
                  <br />
                  are responsible for maintaining the confidentiality of your
                  account and password and for restricting access to your
                  account,
                  <br />
                  and you agree to accept responsibility for all activities that
                  occur under your account or password. Dollarealms does sell
                  <br />
                  products for children, but it sells them to adults, who can
                  purchase with a credit card or other permitted payment method.
                  If
                  <br />
                  you are under 18, you may use the Dollarealms Services only
                  with involvement of a parent or guardian. Parents and
                  guardians may
                  <br />
                  create profiles for teenagers in their Dollarealms Household.
                  Alcohol listings on Dollarealms are intended for adults. You
                  must
                  <br />
                  be at least 21 years of age to purchase alcohol, or use any
                  site functionality related to alcohol. Dollarealms reserves
                  the right
                  <br />
                  to refuse service, terminate accounts, terminate your rights
                  to use Dollarealms Services, remove or edit content, or cancel
                  orders
                  <br />
                  in its sole discretion.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT
                </Typography>
                <Typography variant="body2">
                  You may post reviews, comments, photos, videos, and other
                  content; send e-cards and other communications; and submit
                  suggestions,
                  <br />
                  ideas, comments, questions, or other information, so long as
                  the content is not illegal, obscene, threatening, defamatory,
                  invasive
                  <br />
                  of privacy, infringing of intellectual property rights
                  (including publicity rights), or otherwise injurious to third
                  parties or
                  <br />
                  objectionable, and does not consist of or contain software
                  viruses, political campaigning, commercial solicitation, chain
                  <br />
                  letters, mass mailings, or any form of "spam" or unsolicited
                  commercial electronic messages. You may not use a false e-mail
                  <br />
                  address, impersonate any person or entity, or otherwise
                  mislead as to the origin of a card or other content.
                  Dollarealms reserves the
                  <br />
                  right (but not the obligation) to remove or edit such content,
                  but does not regularly review posted content. If you do post
                  content
                  <br />
                  or submit material, and unless we indicate otherwise, you
                  grant Dollarealms a nonexclusive, royalty-free, perpetual,
                  irrevocable,
                  <br />
                  and fully sublicensable right to use, reproduce, modify,
                  adapt, publish, perform, translate, create derivative works
                  from,
                  <br />
                  distribute, and display such content throughout the world in
                  any media. You grant Dollarealms and sublicensees the right to
                  use the
                  <br />
                  name that you submit in connection with such content, if they
                  choose. You represent and warrant that you own or otherwise
                  <br />
                  control all of the rights to the content that you post; that
                  the content is accurate; that use of the content you supply
                  does not
                  <br />
                  violate this policy and will not cause injury to any person or
                  entity; and that you will indemnify Dollarealms for all claims
                  <br />
                  resulting from content you supply. Dollarealms has the right
                  but not the obligation to monitor and edit or remove any
                  activity or
                  <br />
                  content. Dollarealms takes no responsibility and assumes no
                  liability for any content posted by you or any third party.
                  <br />
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  INTELLECTUAL PROPERTY COMPLAINTS
                </Typography>
                <Typography variant="body2">
                  Dollarealms respects the intellectual property of others. If
                  you believe that your intellectual property rights are being
                  <br />
                  infringed, please follow our Notice and Procedure for Making
                  Claims of Copyright Infringement.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  RISK OF LOSS
                </Typography>
                <Typography variant="body2">
                  All purchases of physical items from Dollarealms are made
                  pursuant to a shipment contract. This means that the risk of
                  loss and title
                  <br />
                  for such items pass to you upon our delivery to the carrier.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  RETURNS, REFUNDS AND TITLE
                </Typography>
                <Typography variant="body2">
                  Dollarealms does not take title to returned items until the
                  item arrives at our address . At our discretion, a refund may
                  be issued
                  <br />
                  without requiring a return. In this situation, Dollarealms
                  does not take title to the refunded item. For more information
                  about
                  <br />
                  our returns and refunds, please see our Returns and/or Refunds
                  Policy(ies).
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  PRODUCT DESCRIPTIONS
                </Typography>
                <Typography variant="body2">
                  Dollarealms attempts to be as accurate as possible. However,
                  Dollarealms does not warrant that product descriptions or
                  other
                  <br />
                  content of any Dollarealms Service is accurate, complete,
                  reliable, current, or error-free. If a product offered by
                  <br />
                  Dollarealms itself is not as described, your sole remedy is to
                  return it in unused condition
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  PRICING
                </Typography>
                <Typography variant="body2">
                  "List Price" means the suggested retail price of a product as
                  provided by a manufacturer, supplier, or seller. We regularly
                  <br />
                  check List Prices against prices recently found on Dollarealms
                  and other retailers. Certain products may have a "Was Price"
                  <br />
                  displayed, which is determined using recent price history of
                  the product on Dollarealms . With respect to items sold by
                  Dollarealms
                  <br />
                  , we cannot confirm the price of an item until you order.
                  Despite our best efforts, a small number of the items in our
                  catalog may
                  <br />
                  be mispriced. If the correct price of an item sold by
                  Dollarealms is higher than our stated price, we will, at our
                  discretion,
                  <br />
                  either contact you for instructions before shipping or cancel
                  your order and notify you of such cancellation. Other
                  merchants may
                  <br />
                  follow different policies in the event of a mispriced item.
                  <br />
                  <br />
                  We generally do not charge your credit card until after your
                  order has entered the shipping process or, for digital
                  products, until
                  <br />
                  we make the digital product available to you.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  APP PERMISSIONS
                </Typography>
                <Typography variant="body2">
                  When you use apps created by Dollarealms , such as the
                  Dollarealms App , you may grant certain permissions to us for
                  your device.
                  <br />
                  Most mobile devices provide you with information about these
                  permissions. To learn more about these permissions, click
                  here.
                  <br />
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  SANCTIONS AND EXPORT POLICY
                </Typography>
                <Typography variant="body2">
                  You may not use any Dollarealms Service if you are the subject
                  of U.S. sanctions or of sanctions consistent with U.S. law
                  imposed by
                  <br />
                  the governments of the country where you are using Dollareams
                  Services. You must comply with all U.S. or other export and
                  <br />
                  re-export restrictions that may apply to goods, software
                  (including Dollarealms Software), technology, and services.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  OTHER BUSINESSES
                </Typography>
                <Typography variant="body2">
                  Parties other than Dollarealms operate stores, provide
                  services or software, or sell product lines through the
                  Dollarealms Services. <br />
                  In addition, we provide links to the sites of affiliated
                  companies and certain other businesses. If you purchase any of
                  the products <br />
                  or services offered by these businesses or individuals, you
                  are purchasing directly from those third parties, not from
                  Dollarealms <br />. We are not responsible for examining or
                  evaluating, and we do not warrant, the offerings of any of
                  these businesses or <br />
                  individuals (including the content of their Web sites).
                  Dollarealms does not assume any responsibility or liability
                  for <br />
                  the actions, product, and content of all these and any other
                  third parties. You should carefully review their privacy
                  statements and <br />
                  other conditions of use.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
                </Typography>
                <Typography variant="body2">
                  THE DOLLAREALMS SERVICES AND ALL INFORMATION, CONTENT,
                  MATERIALS, PRODUCTS (INCLUDING SOFTWARE) <br />
                  AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
                  YOU THROUGH THE DOLLAREALMS SERVICES
                  <br />
                  ARE PROVIDED BY DOLLAREALMS ON AN "AS IS" AND "AS AVAILABLE"
                  BASIS,
                  <br />
                  UNLESS OTHERWISE SPECIFIED IN WRITING. DOLLAREALMS MAKES NO
                  REPRESENTATIONS OR WARRANTIES OF ANY KIND, <br />
                  EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE DOLLAREALMS
                  SERVICES, <br />
                  OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
                  SOFTWARE) OR OTHER
                  <br />
                  SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
                  THROUGH
                  <br />
                  THE DOLLAREALMS SERVICES, UNLESS OTHERWISE SPECIFIED IN
                  WRITING.
                  <br />
                  YOU EXPRESSLY AGREE THAT YOUR USE OF THE DOLLAREALMS SERVICES
                  IS
                  <br />
                  AT YOUR SOLE RISK.
                  <p />
                  TO THE FULL EXTENT PERMISSIBLE BY LAW, DOLLAREALMS DISCLAIMS
                  ALL
                  <br />
                  WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                  IMPLIED WARRANTIES OF MERCHANTABILITY <br />
                  AND FITNESS FOR A PARTICULAR PURPOSE. DOLLAREALMS DOES NOT
                  WARRANT THAT THE DOLLAREALMS SERVICES, INFORMATION, CONTENT,{" "}
                  <br /> MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER
                  SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
                  THROUGH THE <br /> DOLLAREALMS SERVICES, DOLLAREALMS' SERVERS
                  OR ELECTRONIC COMMUNICATIONS SENT FROM DOLLAREALMS ARE FREE OF
                  VIRUSES OR OTHER <br />
                  HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW,
                  DOLLAREALMS WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
                  ARISING FROM THE USE OF ANY DOLLAREALMS SERVICE, OR
                  <br />
                  FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
                  SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
                  <br />
                  AVAILABLE TO YOU THROUGH ANY DOLLAREALMS SERVICE, INCLUDING,
                  BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
                  <br />
                  CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  DAPPLICABLE LAW
                </Typography>
                <Typography variant="body2">
                  By using any Dollarealms Service, you agree that applicable
                  federal law, and the laws of the state of New Jersey, without
                  <br />
                  regard to principles of conflict of laws, will govern these
                  Conditions of Use and any dispute of any sort that might arise
                  <br />
                  between you and Dollarealms.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  SITE POLICIES, MODIFICATION, AND SEVERABILITY
                </Typography>
                <Typography variant="body2">
                  Please review our other policies, such as our pricing policy,
                  posted on this site. These policies also govern your use of
                  <br />
                  Dollarealms Services. We reserve the right to make changes to
                  our site, policies, Service Terms, and these Conditions of Use
                  at any
                  <br />
                  time. If any of these conditions shall be deemed invalid,
                  void, or for any reason unenforceable, that condition shall be
                  deemed
                  <br />
                  severable and shall not affect the validity and enforceability
                  of any remaining condition.
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  <p>
                    OUR ADDRESS
                    <br />
                    Dollarealms LLC.
                    <br />
                    430 S 19TH S,
                    <br />
                    Newark, NJ 07103 ADDITIONAL DOLLAREALMS SOFTWARE TERMS
                    <br />
                  </p>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  The following terms (“Software Terms”) apply to any software
                  (including any updates or upgrades to the software) and any
                  <br />
                  related documentation we make available to you in connection
                  with Dollarealms Services (the "Dollarealms Software").
                  <br />
                  <p></p>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Use of the Dollarealms Software. You may use Dollarealms
                  Software solely for purposes of enabling you to use the
                  Dollarealms
                  <br />
                  Services as provided by Dollarealms , and as permitted by
                  these Conditions of Use and any Service Terms. You may not
                  incorporate
                  <br />
                  any portion of the Dollarealms Software into other programs or
                  compile any portion of it in combination with other programs,
                  or
                  <br />
                  otherwise copy (except to exercise rights granted in this
                  section), modify, create derivative works of, distribute,
                  assign
                  <br />
                  any rights to, or license the Dollarealms Software in whole or
                  in part. All software used in any Dollarealms Service is the
                  property
                  <br />
                  of Dollarealms or its software suppliers and is protected by
                  United States and international copyright laws.
                  <br />
                  <br />
                  <p></p>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Use of Third Party Services. When you use the Dollarealms
                  Software, you may also be using the services of one or more
                  third
                  <br />
                  parties, such as a wireless carrier or a mobile software
                  provider. Your use of these third party services may be
                  subject to the
                  <br />
                  separate policies, terms of use, and fees of these third
                  parties.
                  <br />
                  <p></p>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  No Reverse Engineering. You may not reverse engineer,
                  decompile or disassemble, tamper with, or bypass any security
                  associated with
                  <br />
                  the Dollarealms Software, whether in whole or in part.
                  <br />
                  <p></p>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Updates. We may offer automatic or manual updates to the
                  Dollarealms Software at any time and without notice to you.
                  <br />
                  <p></p>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Government End Users. If you are a U.S. Government end user,
                  we are licensing the Dollarealms Software to you as a
                  "Commercial
                  <br />
                  Item" as that term is defined in the U.S. Code of Federal
                  Regulations (see 48 C.F.R. § 2.101), and the rights we grant
                  you
                  <br />
                  to the Dollarealms Software are the same as the rights we
                  grant to all others under these Conditions of Use.
                  <br />
                  <br />
                  <p></p>
                </Typography>
                <Typography variant="body2">
                  Conflicts. In the event of any conflict between these
                  Conditions of Use and any other Dollarealms or third-party
                  terms applicable
                  <br />
                  to any portion of Dollarealms Software, such as open-source
                  license terms, such other terms will control as to that
                  portion of
                  <br />
                  the Dollarealms Software and to the extent of the conflict.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  HOW TO SERVE A SUBPOENA OR OTHER LEGAL PROCESS
                </Typography>
                <Typography variant="body2">
                  Dollarealms accepts service of subpoenas or other legal
                  process only through Dollarealms' national registered agent.
                  Subpoenas or
                  <br />
                  other legal process may be served by sending them to the
                  following address:
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Dollarealms LLC.
                  <br />
                  430 S 19TH S,
                  <br />
                  Newark, NJ 07103
                  <br />
                  Attn: Legal Department – Legal Process
                  <br />
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Please note also that providing detailed and accurate
                  information at the outset will facilitate efficient processing
                  of your
                  <br />
                  request. That information will include, for example, e-mail
                  and/or credit card number used to make purchases for retail
                  purchase
                  <br />
                  information; the name, e-mail, and physical address of a
                  seller for seller information; device serial number for
                  Dollarealms
                  <br />
                  devices; and IP address and complete time stamps.
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  NOTICE AND PROCEDURE FOR MAKING CLAIMS OF INTELLECTUAL
                  PROPERTY INFRINGEMENT
                </Typography>
                <Typography variant="body2">
                  If you believe that your intellectual property rights have
                  been infringed, please submit your complaint using our online
                  form.
                  <br />
                  This form may be used to report all types of intellectual
                  property claims including, but not limited to, copyright,
                  trademark, and patent claims.
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  We respond quickly to the concerns of rights owners about any
                  alleged infringement, and we terminate repeat infringers in
                  <br />
                  appropriate circumstances
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  We offer the following alternative to our online form for
                  copyright complaints only. You may submit written claims of
                  <br />
                  copyright infringement to our Copyright Agent at:
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Copyright Agent
                  <br />
                  Dollarealms Legal Department,
                  <br />
                  Dollarealms LLC.
                  <br />
                  430 S 19TH S,
                  <br />
                  Newark, NJ 07103
                  <br />
                  phone: (908) 427-0464
                  <br />
                  e-mail: dollarealms@gmail.com
                  <br />
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Written claims concerning copyright infringement must include
                  the following information:
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "10px" }}
                >
                  A physical or electronic signature of the person authorized to
                  act on behalf of the owner of the copyright interest;
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "10px" }}
                >
                  A description of the copyrighted work that you claim has been
                  infringed upon
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  A description of where the material that you claim is
                  infringing is located on the site;
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Your address, telephone number, and e-mail address;
                  <br />
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  A statement by you that you have a good-faith belief that the
                  disputed use is not authorized by the copyright owner, its
                  agent,
                  <br />
                  or the law; and
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  A statement by you, made under penalty of perjury, that the
                  above information in your notice is accurate and that you are
                  the
                  <br />
                  copyright owner or authorized to act on the copyright owner's
                  behalf.
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                ></Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginTop: "20px" }}
                ></Typography>
              </DialogContentText>
            </DialogContent>
            <hr />
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={isYes}
            autoHideDuration={3000}
            onClose={handleClose}
          ></Snackbar>
        </Grid>
      </Grid>
    </Stack>
  );
}
