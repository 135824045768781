import React from "react";
import "./CheckoutProduct.css";
import { useStateValue } from "./StateProvider";

function CheckoutProduct({ id, image, title, price, rating, hideButton }) {
  const [{ basket }, dispatch] = useStateValue();

  const removeFromBasket = () => {
    //remove the item from basket
    dispatch({ type: "REMOVE_FROM_BASKET", id: id });
  };

  return (
    <div className="checkoutProduct">
      <img className="checkoutProduct__image" src={image} alt="imag" />
      <div className="checkoutProduct__info" key={id}>
        <h6 className="checkoutProduct__title">{title}</h6>
        <div className="checkoutProduct__rating">
          {Array(rating)
            .fill()
            .map((_, index) => {
              return <p className="star_color" key={index}>&#9734;</p>;
            })}
        </div>
        <p className="checkoutProduct__price">
     <small> $ </small>
     <strong> {price} </strong>
   </p>
        {!hideButton && (
          <button onClick={removeFromBasket}> Remove from Cart </button>
        )}
      </div>
      
    </div>
  );
}

export default CheckoutProduct;
